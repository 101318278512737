import React, { useCallback, useEffect } from 'react';
import { Image, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';

import ki_nrw_logo from '../../res/ki-nrw-logo.svg';
import landingpage_image from '../../res/landingPage.svg';
import { ReactComponent as Info } from '../../res/Info.svg';

import { SecondaryOutlineButton, LightGreenButton } from '../../features/button/Button';
import FooterNav from '../../features/footerNav/FooterNav';

import styles from './LandingLoginPage.module.css';
import fontStyles from '../../features/text/Text.module.css';
import MobileModal from '../../features/mobileModal/MobileModal';

/**
 * render landingLoginPage component
 */
const LandingLoginPage: React.FC = () => {
  const postLoginDestination = '/intro';
  const navigate = useNavigate();
  const [t] = useTranslation('landing');
  const [commonT] = useTranslation('common');

  const { initialized: kcInit, keycloak } = useKeycloak();

  const loginCallback = useCallback(
    async () => {
      // Using this indirect way to navigate bc keycloak?.login() overwrites
      // the browser history
      const loginURL = keycloak?.createLoginUrl();
      window.location.assign(loginURL);
    },
    [],
  );

  const registerCallback = useCallback(
    async () => {
      // Using this indirect way to navigate bc keycloak?.login() overwrites
      // the browser history
      const regURL = keycloak?.createRegisterUrl();
      window.location.assign(regURL);
    },
    [],
  );

  useEffect(() => {
    // should be called from a useEffect otherwise it will be triggered during rendering!
    // and cause setstate-in-render error
    if (keycloak?.authenticated) {
      navigate(postLoginDestination);
    }
  }, [keycloak]);

  const buttonBar: () => JSX.Element = () => {
    if (!kcInit) {
      return (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      );
    }
    if (!keycloak.authenticated) {
      return (
        <>
          <LightGreenButton
            className={styles.loginButton}
            onClick={loginCallback}
          >
            {commonT('log-in')}
          </LightGreenButton>
          <SecondaryOutlineButton
            className={styles.registerButton}
            onClick={registerCallback}
          >
            {commonT('register')}
          </SecondaryOutlineButton>
        </>
      );
    }

    return (
      <>
        <LightGreenButton
          variant="success"
          className={styles.loginButton}
          onClick={() => {
            navigate('/intro');
            window.location.reload();
          }}
        >
          {commonT('continue')}
        </LightGreenButton>
        <SecondaryOutlineButton
          variant="outline-light"
          className={styles.registerButton}
          onClick={() => {
          }}
        >
          {commonT('log-out')}
        </SecondaryOutlineButton>
      </>
    );
  };

  return (
    <div className={styles.body}>
      <div className={styles.bodyTop}>
        <div className={styles.contents}>
          <div className={`${styles.detailsHeader} ${fontStyles.HeaderPages}`}>
            {t('title')}
          </div>
          <div className={`${styles.detailsSubHeader} ${fontStyles.SubHeaderPages}`}>
            {t('subtitle')}
          </div>
          <div className={`${styles.detailsText} ${fontStyles.text}`}>
            {t('description')}
          </div>
          <a
            className={`${fontStyles.info} ${styles.moreInfo} `}
            target="_blank"
            // eslint-disable-next-line max-len
            href="https://www.iais.fraunhofer.de/de/geschaeftsfelder/cognitive-business-optimization/analyse-geschaeftsdokumente-prozesse-mit-ki.html"
            rel="noreferrer"
          >
            <Info />
            &nbsp;&nbsp;
            <span className={styles.moreInfoButtonText}>{t('more-information')}</span>
          </a>
        </div>
        <Image src={landingpage_image} className={styles.mainImage} />
      </div>
      <div className={styles.buttonBar}>
        {buttonBar()}
      </div>
      <div className={styles.footerLogo}>
        <a aria-label="ki-nrw-link" href="https://www.ki.nrw/">
          <Image
            src={ki_nrw_logo}
            className={styles.poweredByLogo}
          />
        </a>
      </div>
      <FooterNav />
      <MobileModal />
    </div>
  );
};

export default LandingLoginPage;
